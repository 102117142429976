import React, { useEffect, useState } from "react";
import styled from "styled-components";
import smoothscroll from "smoothscroll-polyfill";
import { FaPhoneAlt, FaArrowDown, FaBars, FaTimes } from "react-icons/fa";
import { Link } from "gatsby";

if (typeof window !== "undefined") {
  smoothscroll.polyfill();
}

const NavStyles = styled.nav`
  @media (max-width: 767px) {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #286544;
    z-index: 9999;
    top: 0;
    left: -100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;

    ${(props) =>
      props.isMobileNavVisible &&
      `
      left: 0;
      opacity: 1;
      visibility: visible;
    `}
  }

  @media (min-width: 768px) {
    display: block;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    padding: 20px 10px 0;
    text-align: center;

    @media (min-width: 768px) {
      display: inline-block;
    }

    a {
      font-size: 1.6rem;
      text-transform: uppercase;
      font-weight: 700;
      text-decoration: none;
      color: #fff;
    }
  }
`;

const MobnavStyles = styled.a`
  border: 2px solid #ffffff;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #ffffff;
  z-index: 9999;
  position: relative;
  margin-left: auto;
`;

const PhoneStyles = styled.div`
  display: none;
  align-items: center;
  padding-top: 23px;

  @media (min-width: 768px) {
    display: flex;
  }

  a {
    font-weight: 700;
    text-decoration: none;
    color: #fff;
  }
`;

// const MobNavOverlay = styled.div`
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   background: #000000;
//   z-index: 9999;
//   top: 0;
//   left: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   display: none;
// `;

const navItems = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Landscaping",
    link: "/landscaping",
  },
  {
    name: "News",
    link: "/news",
  },
  {
    name: "Contact",
    link: "/contact",
  },
];

const Nav = () => {
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);

  useEffect(() => {
    if (isMobileNavVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    // Clean up the effect
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMobileNavVisible]);

  function scroller(targ, e) {
    e.preventDefault();
    const element = document.getElementById(targ);
    const y = element.getBoundingClientRect().top;
    //console.log(y);
    window.scroll({ top: y, behavior: "smooth" });
  }

  return (
    <>
      <NavStyles isMobileNavVisible={isMobileNavVisible}>
        <ul>
          {navItems.map((item, index) => (
            <li key={index}>
              <Link to={item.link}>{item.name}</Link>
            </li>
          ))}
          {/* <li>
            <a href="/#" onClick={(event) => scroller("intro", event)}>
              About
            </a>
          </li>
          <li>
            <a href="/#" onClick={(event) => scroller("gallery", event)}>
              Gallery
            </a>
          </li>
          <li>
            <a href="/#" onClick={(event) => scroller("contact", event)}>
              Contact
            </a>
          </li> */}
        </ul>
      </NavStyles>
      <MobnavStyles
        className="mob-nav hide-medium"
        onClick={() => setIsMobileNavVisible(!isMobileNavVisible)}
      >
        {!isMobileNavVisible ? (
          <FaBars style={{ color: "#ffffff" }} />
        ) : (
          <FaTimes />
        )}
      </MobnavStyles>
    </>
  );
};

export default Nav;
