import React from 'react'
import styled from 'styled-components';

const FormStyles = styled.form`
    input, textarea {
        width: 100%;
        border-radius: 5px;
        background-color: #E1E9E5;
        border: 0;
        padding: 20px 15px;
        margin: 5px 0 15px;
    }

    input[type=submit]{
        background-color: #286544;
        width: auto;
        color: white;
        padding: 10px 15px;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
    }
`;

const ContactForm = () => {
    return (
        <>
            <FormStyles name="contactus" className="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contactus" />
                <label>Name <input type="text" name="name" /></label>

                <label>Email <input type="email" name="email" /></label>

                <label>Telephone number <input type="tel" name="tel" /></label>

                <label>Message <textarea name="message" id="" cols="20" rows="10"></textarea></label>

                <input type="submit" name="sbmit" value="Send" />
            </FormStyles>
        </>
    )
}

export default ContactForm
