import React from "react";
import styled from "styled-components";
import Herocontent from "./Herocontent";
import Nav from "./Nav";
import { StaticImage } from "gatsby-plugin-image";
import Link from "gatsby-link";
import hero from "../assets/images/CardiffGardenRooms.jpg";

const HeroStyles = styled.div`
  ${(props) =>
    props.hasHero
      ? `
          background-image: url(${hero});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          height: 55vw;
          min-height: 500px;
          max-height: 950px;
        `
      : `
          background: none;
          height: 69px;
        `}

  position: relative;
  display: flex;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    // background-image: linear-gradient(
    //   -180deg,
    //   rgba(238, 238, 238, 0) 15%,
    //   rgba(18, 18, 18, 0.45) 65%
    // );
    z-index: 0;
  }
`;

const HeaderStyles = styled.header`
  position: absolute;
  width: 100%;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 60%;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
  }
  .container {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 1;
    align-items: flex-start;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
  }
`;

const Header = ({ hero, heroTitle, heroText }) => {
  return (
    <HeroStyles className="hero" hasHero={hero !== false}>
      <HeaderStyles hasHero={hero !== false}>
        <div className="container">
          <Link to="/">
            <StaticImage
              src="../assets/images/cardiff-garden-rooms.png"
              alt="Cardif Garden Rooms"
              width={115}
              height={115}
            />
          </Link>
          <Nav />
        </div>
      </HeaderStyles>
      {hero !== false && (
        <Herocontent heroTitle={heroTitle} heroText={heroText} />
      )}
    </HeroStyles>
  );
};

export default Header;
