import React from "react";
import styled from "styled-components";

const HeroContentStyles = styled.div`
  margin-top: auto;
  position: relative;

  h1 {
    @media (min-width: 768px) {
      max-width: 400px;
    }
  }

  p {
    color: white;
    @media (min-width: 768px) {
      font-size: 2.2rem;
    }
  }
`;

const Herocontent = ({ heroTitle, heroText }) => {
  return (
    <HeroContentStyles className="container hero__content">
      <div className="grid">
        <div className="grid__1 pb-none grid--75">
          {heroTitle && <h1>{heroTitle}</h1>}
          {!heroTitle && <h1>Cardiff Garden&nbsp;Rooms</h1>}
          {heroText && <p>{heroText}</p>}
        </div>
      </div>
    </HeroContentStyles>
  );
};

export default Herocontent;
