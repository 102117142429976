import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    @charset "UTF-8";
/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: subpixel-antialiased;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: #286544;
}

img {
  max-width: 100%;
  height: auto;
}

/**
 * Basic typography style for copy text
 */
html, body {
  color: #5B5B5B;
  font-family: "Lato", sans-serif;
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  letter-spacing: 0.5px;
}

p {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
@media (min-width: 768px) {
  p {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
p:first-of-type {
  margin-top: 0;
}
p.sub {
  color: #286544;
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h1 {
  color: white;
  font-size: 3.3rem;
}
@media (min-width: 768px) {
  h1 {
    font-size: 5rem !important;
  }
}

h2 {
  font-size: 2rem;
  line-height: 2.6rem;
  color: #286544;
}
@media (min-width: 768px) {
  h2 {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
}

h3 {
  color: #286544;
}

.btn {
  background-color: #286544;
  width: auto;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  text-transform: uppercase;
}

ul li {
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  ul li {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}
ul.leaf {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.leaf li::before {
  content: "🌱 ";
}

.text-center {
  text-align: center;
}

img.rounded {
  border-radius: 100%;
}

.flex {
  display: flex;
}
.flex--icons {
  flex-direction: column;
}
@media (min-width: 768px) {
  .flex--icons {
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }
}
.flex--icons h3 {
  margin-top: 5px;
}
@media (min-width: 768px) {
  .flex--icons h3 {
    margin: 0;
  }
}

.bg-green {
  background-color: #E1E9E5;
}

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.mt-none {
  margin-top: 0 !important;
}

.mb-none {
  margin-bottom: 0 !important;
}

.mt-sml {
  margin-top: 10px !important;
}
@media (min-width: 768px) {
  .mt-sml {
    margin-top: 20px;
  }
}

.mb-sml {
  margin-bottom: 10px !important;
}
@media (min-width: 768px) {
  .mb-sml {
    margin-bottom: 20px;
  }
}

.mt-med {
  margin-top: 30px !important;
}
@media (min-width: 768px) {
  .mt-med {
    margin-top: 40px;
  }
}

.mb-med {
  margin-bottom: 30px !important;
}
@media (min-width: 768px) {
  .mb-med {
    margin-bottom: 40px;
  }
}

.pb-none {
  padding-bottom: 0 !important;
}

.pt-none {
  padding-top: 0;
}

hr {
  border: 0;
  background-color: #696969;
  height: 1px;
}

@media screen and (max-width: 767px) {
  .hide-mob {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hide-medium {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hide-large {
    display: none !important;
  }
}

@media (min-width: 768px) {
  img.fl-left {
    float: left;
    max-width: 350px;
    margin-right: 20px;
  }
  img.fl-left ~ ul {
    overflow: auto;
  }
}
.grid {
  margin: -10px;
}
.grid [class^=grid__] {
  padding: 10px;
}
@media (min-width: 768px) {
  .grid [class^=grid__] {
    padding: 15px;
  }
}
@media (min-width: 1024px) {
  .grid [class^=grid__] {
    padding: 15px 35px;
  }
}
@media (min-width: 768px) {
  .grid {
    display: flex;
    flex-wrap: wrap;
    margin: -15px -20px;
  }
  .grid__1 {
    width: 100%;
  }
  .grid__2 {
    width: 50%;
  }
  .grid__3 {
    width: 33.3333333333%;
  }
  .grid__4 {
    width: 25%;
  }
  .grid__5 {
    width: 20%;
  }
  .grid__6 {
    width: 16.6666666667%;
  }
  .grid__7 {
    width: 14.2857142857%;
  }
  .grid__8 {
    width: 12.5%;
  }
  .grid__9 {
    width: 11.1111111111%;
  }
  .grid__10 {
    width: 10%;
  }
  .grid__11 {
    width: 9.0909090909%;
  }
  .grid__12 {
    width: 8.3333333333%;
  }
}
@media (min-width: 1024px) {
  .grid--75 {
    width: 70%;
  }
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: 1080px;
  /* 1 */
  margin-left: auto;
  /* 2 */
  margin-right: auto;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  padding-right: 20px;
  /* 3 */
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
}
@media (min-width: 768px) {
  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.container--wide {
  max-width: 1280px;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .container--wide {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.box {
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}
.box p:last-of-type {
  margin-bottom: 0;
}
`;

export default GlobalStyles;
