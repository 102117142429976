import React from "react";
import ContactDetails from "../components/ContactDetails";
import ContactForm from "../components/ContactForm";
import styled from "styled-components";

const StyledContact = styled.section`
  background-color: ${(props) => (props.inverted ? "#E1E9E5" : "#fff")};

  input,
  textarea {
    background-color: ${(props) => (props.inverted ? "#fff" : "#E1E9E5")};
  }
`;

const Contact = ({ inverted }) => {
  return (
    <StyledContact id="contact" inverted={inverted}>
      <div className="container">
        <div className="grid">
          <div className="grid__1 pb-none grid--3/4">
            <h2>Contact us</h2>
          </div>
          <div className="grid__2">
            <ContactDetails />
          </div>
          <div className="grid__2">
            <ContactForm />
          </div>
        </div>
      </div>
    </StyledContact>
  );
};

export default Contact;
